
































































































import { defineComponent } from "@vue/composition-api";
import { mapStores } from "pinia";
import { useAuthStore } from "@/pinia/auth";
import { useGebruikerStore } from "@/pinia/gebruiker";

interface State {
	loading: boolean;
	lidGesproken: boolean;
	besproken: string;
}

export default defineComponent({
	data(): State {
		return {
			loading: false,
			lidGesproken: true,
			besproken: "",
		};
	},
	computed: {
		...mapStores(useAuthStore, useGebruikerStore),
	},
	watch: {
		$route: "check",
	},
	async created() {
		await this.check();
	},
	destroyed() {
		this.reset();
	},
	methods: {
		async check() {
			this.loading = true;

			const { id } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			const currentId = this.gebruikerStore.id;

			if (currentId !== numberId) this.gebruikerStore.set(numberId);

			await this.getRequiredInfo();

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.gebruikerStore.getDashboardLidInfo();
		},
		reset() {
			// @ts-expect-error Apply not correctly typed
			Object.assign(this.$data, this.$options.data.apply(this));
		},
		async save() {
			const isValid = await this.$validator.validateAll();

			if (!isValid) return;

			if (!this.authStore.userClub?.coachId) return;

			await this.gebruikerStore.createBelpoging({
				timestamp: new Date().toISOString(),
				lidId: this.gebruikerStore.id,
				coachId: this.authStore.userClub.coachId,
				lidGesproken: this.lidGesproken,
				besproken: this.besproken === "" ? undefined : this.besproken,
			});

			this.$router.push({
				name: "Gebruiker",
				params: { id: String(this.gebruikerStore.id) },
			});
		},
	},
});
